import React from 'react'
import Layout from "../../components/layout";
import HeaderPaginas from '../../components/headerPaginas'
import Seo from "../../components/SEO/SEO"
import YAMLData from "./cloud-robusto.yaml"
import { IconContext } from "react-icons"
import { GrClock, GrInProgress, GrGrow, GrMap, GrDocumentLocked } from "react-icons/gr"

import "../../styles/cloud-robusto.scss";
import "../../styles/tarjetas_servicios.scss";

// import { StaticImage } from 'gatsby-plugin-image';


export default function CloudRobusto(props) {

    const datos = YAMLData.cloud_robusto;

    return (
        <Layout>
            <Seo title="Cloud robusto" pathname={props.location.pathname} />
            <HeaderPaginas titulo="Cloud robusto" imagen="/img/cabeceras/bullhost-cloud-robusto.jpg" />
            <div className="limitador intro-cloud">
                <p>{datos.intro}</p>
            </div>
            <div className="limitador cpd-propio">
                <p>{datos.cpd_propio.intro}</p>
                <div className="fila-cards-cpd-propio">
                    <IconContext.Provider value={{ fill: "#1f1f1f", stroke: "#1f1f1f", color: "#1f1f1f", size: "30%", className: "cpd-icono-svg" }}>
                        <div className="card-cpd-propio">
                            <div className="card-cpd-propio__icono">
                                <GrMap />
                            </div>
                            <div className="card-cpd-propio__info">
                                <h3>{datos.cpd_propio.variables.cercania.titulo}</h3>
                                <p>{datos.cpd_propio.variables.cercania.texto}</p>
                            </div>
                        </div>
                        <div className="card-cpd-propio">
                            <div className="card-cpd-propio__icono">
                                <GrGrow />
                            </div>
                            <div className="card-cpd-propio__info">
                                <h3>{datos.cpd_propio.variables.eficiencia.titulo}</h3>
                                <p>{datos.cpd_propio.variables.eficiencia.texto}</p>
                            </div>
                        </div>
                        <div className="card-cpd-propio">
                            <div className="card-cpd-propio__icono">
                                <GrInProgress />
                            </div>
                            <div className="card-cpd-propio__info">
                                <h3>{datos.cpd_propio.variables.latencia.titulo}</h3>
                                <p>{datos.cpd_propio.variables.latencia.texto}</p>
                            </div>
                        </div>
                        <div className="card-cpd-propio">
                            <div className="card-cpd-propio__icono">
                                <GrDocumentLocked />
                            </div>
                            <div className="card-cpd-propio__info">
                                <h3>{datos.cpd_propio.variables.rgpd.titulo}</h3>
                                <p>{datos.cpd_propio.variables.rgpd.texto}</p>
                            </div>
                        </div>
                    </IconContext.Provider>
                </div>
                <p>{datos.cpd_propio.intro_caracteristicas}</p>

                <div className="fila-cards-cpd-propio-caracteristicas">
                    <div className="card-cpd-propio-caracteristicas">
                        <div className="col-imagen" style={{ backgroundImage: "url(/img/bullhost-cloud-robusto-cpd.jpg)" }}>
                            {/* <StaticImage src="../../../static/img/bullhost-cloud-robusto-cpd.jpg" alt="Cloud robusto" /> */}
                        </div>
                        <div className="col-texto">
                            <div>
                                <h3>{datos.cpd_propio.caracteristicas.ubicacion.titulo}</h3>
                                <p>{datos.cpd_propio.caracteristicas.ubicacion.texto}</p>
                            </div>
                            <div>
                                <h3>{datos.cpd_propio.caracteristicas.seguridad.titulo}</h3>
                                <p>{datos.cpd_propio.caracteristicas.seguridad.texto}</p>

                            </div>
                            <div>

                                <h3>{datos.cpd_propio.caracteristicas.red.titulo}</h3>
                                <p>{datos.cpd_propio.caracteristicas.red.texto}</p>
                            </div>
                            <div>

                                <h3>{datos.cpd_propio.caracteristicas.precision.titulo}</h3>
                                <p>{datos.cpd_propio.caracteristicas.precision.texto}</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="bloque-oscuro equipo-certificado-back">
                <div className="overlay-certificado"></div>
                <div className="limitador equipo-certificado">
                    <h2>{datos.equipo_certificado.titulo}</h2>
                    <p>{datos.equipo_certificado.texto}</p>
                    <ul>
                        {datos.equipo_certificado.certificaciones.map((data, index) => {
                            return <li key={`certificado_${index}`}>{data.certificado}</li>
                        })}
                    </ul>
                </div>
            </div>
        </Layout>
    )
}
